.medium-toolbar-arrow-under:after {
  border-color: #2f1e07 transparent transparent transparent;
  top: 60px; }

.medium-toolbar-arrow-over:before {
  border-color: transparent transparent #2f1e07 transparent; }

.medium-editor-toolbar {
  background-color: #2f1e07;
  border: 1px solid #5b3a0e;
  border-radius: 6px; }
  .medium-editor-toolbar li button {
    background-color: transparent;
    border: none;
    border-right: 1px solid #5b3a0e;
    box-sizing: border-box;
    color: #ffedd5;
    height: 60px;
    min-width: 60px;
    -webkit-transition: background-color .2s ease-in, color .2s ease-in;
            transition: background-color .2s ease-in, color .2s ease-in; }
    .medium-editor-toolbar li button:hover {
      background-color: #030200;
      color: #ffedd5; }
  .medium-editor-toolbar li .medium-editor-button-first {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px; }
  .medium-editor-toolbar li .medium-editor-button-last {
    border-bottom-right-radius: 6px;
    border-right: none;
    border-top-right-radius: 6px; }
  .medium-editor-toolbar li .medium-editor-button-active {
    background-color: #030200;
    color: #ffedd5; }

.medium-editor-toolbar-form {
  background: #2f1e07;
  border-radius: 6px;
  color: #ffedd5; }
  .medium-editor-toolbar-form .medium-editor-toolbar-input {
    background: #2f1e07;
    color: #ffedd5;
    height: 60px; }
    .medium-editor-toolbar-form .medium-editor-toolbar-input::-webkit-input-placeholder {
      color: #ffedd5;
      color: rgba(255, 237, 213, 0.8); }
    .medium-editor-toolbar-form .medium-editor-toolbar-input:-moz-placeholder {
      /* Firefox 18- */
      color: #ffedd5;
      color: rgba(255, 237, 213, 0.8); }
    .medium-editor-toolbar-form .medium-editor-toolbar-input::-moz-placeholder {
      /* Firefox 19+ */
      color: #ffedd5;
      color: rgba(255, 237, 213, 0.8); }
    .medium-editor-toolbar-form .medium-editor-toolbar-input:-ms-input-placeholder {
      color: #ffedd5;
      color: rgba(255, 237, 213, 0.8); }
  .medium-editor-toolbar-form a {
    color: #ffedd5; }

.medium-editor-toolbar-anchor-preview {
  background: #2f1e07;
  border-radius: 6px;
  color: #ffedd5; }

.medium-editor-placeholder:after {
  color: #5b3a0e; }
